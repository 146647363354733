import React from 'react';
import './App.css';

import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import OurWorks from './pages/OurWorks';
import Catalogitem  from './pages/catalog_item';
import View from './pages/View';
import OrderService from './pages/OrderService';
import OrderProduct  from './pages/OrderProduct';
import NotFound from './page-tracker/NotFound';


const App = () => {
  return (
    <BrowserRouter basename="/" >
    <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/shop" element={<Catalog />}></Route>
        <Route path="/ourworks" element={<OurWorks/>} />
        <Route path="/works/:id" element={<Catalogitem/>}/>  
        <Route path="/tovar/:id" element={<View/>}/>
        <Route path="/service" element={<OrderService/>}/>
        <Route path="/order/product" element={<OrderProduct/>}/>
        <Route path="/*" element={<NotFound/>}/>
     </Routes>
    </BrowserRouter>
  );
};

export default App;