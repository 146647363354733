import React, {  useRef, useState, useEffect } from 'react';
import './Shop.css';
import Carousel from 'react-elastic-carousel';
import arrow from '../../assets/arrow.svg';
import arrow2 from '../../assets/arrow2.svg';
import axios from 'axios';
import { HashLink } from 'react-router-hash-link';

const CatalogItem = ({ description, price_from, price_to, imageUrl }) => (
  <div className="Ripor__Shop-catalog-item">
    <img src={imageUrl} alt={description} />
    <p>{description}</p>
    <p> {price_from} - {price_to}₴ </p>
  </div>
);

const Shop = () => {
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const itemsToShow = 4;
  const carouselRefPC = useRef(null);
  const carouselRefMobile = useRef(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_STRAPI_BACK_END}/api/products`, {
        params: {
          populate: '*',
          'pagination[pageSize]': 50 // Збільшено кількість товарів на сторінку
        }
      })
      .then(({ data }) => setProducts(data.data))
      .catch((error) => setError(error));
  }, []);

    if(error) return <p>ERROR</p>
  
  const handlePrevPC = () => {
    carouselRefPC.current.slidePrev();
  };

  const handleNextPC = () => {
    carouselRefPC.current.slideNext();
  };

  const handlePrevMobile = () => {
    carouselRefMobile.current.slidePrev();
  };

  const handleNextMobile = () => {
    carouselRefMobile.current.slideNext();
  };

  return (
    <section>
    <div className="Ripor__Shop">
      <div className="Ripor__Shop-PC">
      <div className="Ripor__Shop-header">
        <h3>Магазин</h3>
        <div className="Ripor__Shop-header-arrow">
          <button onClick={handlePrevPC}>
            <img src={arrow2} alt="arrow" />
          </button>
          <button onClick={handleNextPC}>
            <img src={arrow} alt="arrow2" />
          </button>
        </div>
      </div>
      <div className="Ripor__Shop-catalog">
        <Carousel
          itemsToShow={itemsToShow}
          itemsToScroll={itemsToShow}
          renderArrow={({ type, onClick }) => (
            <div
              className="Ripor__Shop-header-arrow-def"
              onClick={onClick}
            >
              {type === 'PREV' ? (
                <img src={arrow} alt='arrow' />
              ) : (
                <img src={arrow2} alt='arrow2' />
              )}
            </div>
          )}
          ref={carouselRefPC}
        >
          {products.map((item, index) => (
            <HashLink to={`/tovar/${item?.id}#`}>
              <CatalogItem
                key={index}
                description={item?.attributes.name}
                price_from={item?.attributes.price_from}
                price_to={item?.attributes.price_to}
                imageUrl={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes?.picture.data[0].attributes.url}`}
              />
            </HashLink>
          ))}
        </Carousel>
      </div>
      </div>


      <div className="Ripor__Shop-Mobile">
        <div className="Ripor__Shop-header">
          <h3>Магазин</h3>
          <div className="Ripor__Shop-header-arrow">
            <button onClick={handlePrevMobile}>
              <img src={arrow2} alt="arrow" />
            </button>
            <button onClick={handleNextMobile}>
              <img src={arrow} alt="arrow2" />
            </button>
          </div>
        </div>
        <div className="Ripor__Shop-catalog">
          <Carousel
            itemsToShow={2}
            itemsToScroll={2}
            renderArrow={({ type, onClick }) => (
              <div
                className="Ripor__Shop-header-arrow-def"
                onClick={onClick}
              >
                {type === 'PREV' ? (
                  <img src={arrow} alt='arrow' />
                ) : (
                  <img src={arrow2} alt='arrow2' />
                )}
              </div>
            )}
            ref={carouselRefMobile}
          >
            {products.map((item, index) => (
              <HashLink to={`/tovar/${item?.id}#`}>
              <CatalogItem
                key={index}
                description={item?.attributes.name}
                price_from={item?.attributes.price_from}
                price_to={item?.attributes.price_to}
                imageUrl={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes?.picture.data[0].attributes.url}`}
              />
            </HashLink>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Shop;

