import React from 'react';

import { Info } from '../components';
import { Header, Footer} from '../container';

const Catalog = () => {
  return (
    <div className="CatalogPage">
      <Header/>
      <Info/>
      <div className="bgc">
        <Footer/>
      </div>
    </div>
  );
};

export default Catalog;