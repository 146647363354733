
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const storedLastVisitedPage = localStorage.getItem('lastVisitedPage');
    if (storedLastVisitedPage) {
      navigate(storedLastVisitedPage);
    }
  }, [navigate]);

  return null;
};

export default NotFound;
