import React from 'react';

import { OrderServ} from '../components';
import { Footer, Header} from '../container';

const OrderService = () => {
  return (
    <>
    <Header/>
    <OrderServ/>
    <div className="bgc">
      <Footer />
    </div>
    </>
  );
};

export default OrderService;