  import React, { useState, useEffect, useRef } from 'react';
  import './OrderProduct.css';
  import './TextBoxProduct.css'
  import { useNavigate } from 'react-router-dom';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
  import axios from 'axios';

  const OrderProduct = () => {
    
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const form = useRef();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const formatPhone = (value) => {

      const cleanedValue = value.replace(/\D/g, '');

      let formattedValue = cleanedValue.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      return formattedValue;
    };

    const handlePhoneChange = (e) => {
      const inputValue = e.target.value;

      setPhone(formatPhone(inputValue));
    };
    useEffect(() => {
      const storedCartItems = localStorage.getItem('cartItems');
      if (storedCartItems) {
        const parsedCartItems = JSON.parse(storedCartItems);
        setCartItems(parsedCartItems);
      }
    }, []);
    
    const sendEmail = async (e) => {
      e.preventDefault();
      try {

        const userEmail = email;
        const userName = form.current.querySelector('input[name="user_name"]').value;
        const userPhone = form.current.querySelector('input[name="user_phone"]').value;
        const userMessage = message;


        const emailContent = `
          <p><strong>Ім'я замовника:</strong> ${userName}</p>
          <p><strong>Email замовника:</strong> ${userEmail}</p>
          <p><strong>Номер телефону:</strong> ${userPhone}</p>
          <p><strong>Повідомлення:</strong> ${userMessage}</p>

          <p><strong>Список товарів:</strong></p>
          <ul>
          <img src="cid:nyan@example.com" />
            ${cartItems.map((product) => `
              <li>
                <strong>Назва товару:</strong> ${product.name || 'Назва відсутня'},
                <strong>Колір:</strong> ${product.colors || 'Відсутній'},
                <strong>Матеріал:</strong> ${product.materials || 'Відсутній'},
                <strong>Розмір:</strong> ${product.size_mms || 'Відсутній'},
                <strong>Товщина:</strong> ${product.thickness || 'Відсутній'},
                <strong>Кількість:</strong> ${product.size || 'Відсутній'} ${product.complexes},
                <strong>Ціна:</strong> ${product.price_from ? `${product.price_from} - ${product.price_to}₴` : 'Відсутня'}
              </li>`).join('')}
          </ul>
        `;

        
        await axios.post(`${process.env.REACT_APP_STRAPI_BACK_END}/api/email`, {
          to: 'riporkhm@gmail.com',
          subject: 'Замовлення',
          html: emailContent,  
        });
        setShowPopup(true);
      } catch (error) {
        console.error('Failed to send email:', error);
        console.log('Failed to send email:', error.response.data);
        console.error('Unhandled promise rejection:', error);
      }
    };

    const calculateTotalSumfrom = () => {
      let totalSumfrom = 0;
  
      cartItems.forEach((product) => {
        totalSumfrom += product.price_from;
      });
  
      return totalSumfrom;
    };

    const calculateTotalSumto = () => {
      let totalSumto = 0;
  
      cartItems.forEach((product) => {
        totalSumto += product.price_to;
      });
  
      return totalSumto;
    };

    return (
      <section>
      <div className="Ripor__OrderProduct">
        <div className="Ripor__OrderProduct-container">
          <div className="Ripor__OrderProduct-Back" onClick={() => navigate(-1)}>
            <p>
              <FontAwesomeIcon icon={faArrowLeft} />
              <span style={{ marginLeft: '15px' }}>Назад</span>
            </p>
          </div>

          <div className="Ripor__OrderProduct-Info">
            <h2>Замовити продукт</h2>
            <p>Заповніть форму, і ми зв'яжемося з вами найближчим часом.</p>
          </div>

          <div className="Ripor__OrderProduct-TextBoxes">
            <form ref={form} onSubmit={(e) => { e.preventDefault(); sendEmail(); }}>
          
              <div className='TextBoxRow-product'>
                <input
                  name='user_name'
                  className="Text-Box-product1"
                  type="text"
                  placeholder="Ім'я"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <input
                  name='user_phone'
                  className="Text-Box-product1"
                  type="text"
                  placeholder="Номер телефону"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
              <div className="TextBoxRow-product2">
                <input
                  name='user_email'
                  className="Text-Box-product2"
                  type="text"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  name='user_message'
                  className="Text-Box-product2"
                  type="text"
                  value={message}
                  placeholder="Повідомлення"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="Ripor__OrderProduct-button">
                <button type="submit" onClick={sendEmail}>Надіслати</button>
              </div>
            </form>
          </div>
        </div>

        <div className="Ripor__OrderProduct-Basket">
          <p className='Order_Prod'>Ваше замовлення:</p>
          {cartItems.map((product) => (
            <div key={product.id} className="shopping-catalog-content-product">
              <div className="shopping_cart-image">
                  <a href="#basket"><img src={product.imageUrl} alt=""/></a>
              </div>
              <div className="Left-upper-side-product">
                  <p style={{fontSize:'20px'}} className="shopping_name" >
                    {product.name || 'Назва відсутня'}
                  </p>
                  <p className="shopping_color">
                    <span>Колір: </span> {product.color || 'Відсутній'}
                  </p>
                  <div className="shopping-card-counter-product" style={{border:'none'}}>
                  <p>Кількість: <span style={{color:'black'}}>{product.size || 0}</span> {product.complexes}</p>
                </div>
              </div>

              <div className='Right-upper-side'>
                <p>
                  <span>Матеріал: </span> {product.materials || 'Відсутній'}
                </p>
                {product.size_mms && product.size_mms.length > 0 && (
                  <p>
                    <span>Розмір: </span> {product.size_mms.join(', ')}
                  </p>
                )}
                {product.thickness && (
                  <p>
                    <span>Товщина: </span> {product.thickness}
                  </p>
                )}
                <p className="shopping_price-product">
                  <span>Ціна: </span> {`${product.price_from} - ${product.price_to}`}₴
                </p>
              </div>

            </div>
          ))}
            <p className='shopping_total'>До сплати: <span>{`${calculateTotalSumfrom()} - ${calculateTotalSumto()}₴`}</span></p>
        </div>
      </div>

      <div className="Ripor__OrderProduct_Mobile">      
        <div className="Ripor__OrderProduct-Basket">
          <div className="Ripor__OrderProduct-Back" onClick={() => navigate(-1)}>
                <p>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <span style={{ marginLeft: '15px' }}>Назад</span>
                </p>
          </div>
              <p className='Order_Prod'>Ваше замовлення:</p>
              {cartItems.map((product) => (
                <div style={{display:'flex'}} key={product.id} className="shopping-catalog-content-product">
                  <div className="shopping_cart-image">
                      <a href="#basket"><img src={product.imageUrl} alt=""/></a>
                  </div>
                  <div className="Left-upper-side-product ">
                    <h3 className="shopping_name" >
                      {product.name || 'Назва відсутня'}
                    </h3>
                    <p style={{marginTop:'0'}} className="shopping_color">
                      <span>Колір: </span> {product.color || 'Відсутній'}
                    </p>
                    <p>
                      <span>Матеріал: </span> {product.materials || 'Відсутній'}
                    </p>
                    {product.size_mms && product.size_mms.length > 0 && (
                      <p>
                        <span>Розмір: </span> {product.size_mms.join(', ')}
                      </p>
                    )}
                    {product.thickness && (
                      <p>
                        <span>Товщина: </span> {product.thickness}
                      </p>
                    )}
                    <p className="shopping_price" >
                      <span>Ціна: </span> {product.price_from ? `${product.price_from} - ${product.price_to}₴` : 'Відсутня'}
                    </p>
                    <div className="shopping-card-counter-product" style={{border:'none'}}>
                      <p>К-сть: <span style={{color:'black'}}>{product.size || 0}</span> {product.complexes}</p>
                    </div>
                    
                  </div>
                  
                  
                </div>
              ))}
                <p className='shopping_total'>До сплати: <span>{`${calculateTotalSumfrom()} - ${calculateTotalSumto()}₴`}</span></p>
            </div>

            <div className="Ripor__OrderProduct-container">

              <div className="Ripor__OrderProduct-Info">
                <h2>Замовити продукт</h2>
                <p>Заповніть форму, і ми зв'яжемося з вами найближчим часом.</p>
              </div>

              <div className="Ripor__OrderProduct-TextBoxes">
                <form ref={form} onSubmit={(e) => { e.preventDefault(); sendEmail(); }}>
                  
                    <div className='TextBoxRow-product'>
                      <input
                        name='user_name'
                        className="Text-Box-product1"
                        type="text"
                        placeholder="Ім'я"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <input
                        name='user_phone'
                        className="Text-Box-product1"
                        type="text"
                        placeholder="Номер телефону"
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </div>
                    <div className="TextBoxRow-product2">
                      <input
                        name='user_email'
                        className="Text-Box-product2"
                        type="text"
                        value={email}
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        name='user_message'
                        className="Text-Box-product2"
                        type="text"
                        value={message}
                        placeholder="Повідомлення"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="Ripor__OrderProduct-button">
                      <button type="button" onClick={sendEmail}>Надіслати</button>
                    </div>
                </form>
              </div>
          </div>
        
      </div>

      <div className="Ripor__OrderProduct_Mobile-375px">
        <div className="Ripor__OrderProduct-Basket">
          <p className='Order_Prod'>Ваше замовлення:</p>
          {cartItems.map((product) => (
            
            <div key={product.id} className="shopping-catalog-content-product">
              <div className="shopping_cart-image" >
                  <a href="#basket"><img src={product.imageUrl} alt=""/></a>
              </div>

              
              <div className="Left-upper-side-product" style={{paddingLeft:'0'}}>
                <p className="shopping_name" >
                  {product.name || 'Назва відсутня'}
                </p>
                <p className="shopping_color">
                  <span>Колір: </span> {product.color || 'Відсутній'}
                </p>
              </div>
              <div className="shopping-card-counter-product" style={{border:'none'}}>
                <p style={{ marginTop:'220px', fontSize:'16px'}}>К-сть: <span style={{color:'black'}}>{product.size || 0}</span> {product.complexes}</p>
              </div>
              <p className="shopping_price" style={{ paddingTop:'55px'}}>
                <span>Ціна: </span> {product.description ? `₴${product.description * product.size}` : 'Відсутня'}
              </p>
              <p className='shopping_total'>До сплати: <span>{`${calculateTotalSumfrom()} - ${calculateTotalSumto()}₴`}</span></p>
              </div>
          ))}
        </div>

        <div className="Ripor__OrderProduct-container">
          <div className="Ripor__OrderProduct-Back" onClick={() => navigate(-1)}>
            <p>
              <FontAwesomeIcon icon={faArrowLeft} />
              <span style={{ marginLeft: '15px' }}>Назад</span>
            </p>
          </div>

          <div className="Ripor__OrderProduct-Info">
            <h2>Замовити продукт</h2>
            <p>Заповніть форму, і ми зв'яжемося з вами найближчим часом.</p>
          </div>

          <div className="Ripor__OrderProduct-TextBoxes">
          <form ref={form} onSubmit={(e) => { e.preventDefault(); sendEmail(); }}>
        
        <div className='TextBoxRow-product'>
          <input
            name='user_name'
            className="Text-Box-product1"
            type="text"
            placeholder="Ім'я"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            name='user_phone'
            className="Text-Box-product1"
            type="text"
            placeholder="Номер телефону"
            value={phone}
            onChange={handlePhoneChange}
          />
        </div>
        <div className="TextBoxRow-product2">
          <input
            name='user_email'
            className="Text-Box-product2"
            type="text"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            name='user_message'
            className="Text-Box-product2"
            type="text"
            value={message}
            placeholder="Повідомлення"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="Ripor__OrderProduct-button">
          <button type="button" onClick={sendEmail}>Надіслати</button>
        </div>
      </form>
          </div>
        </div>

        
      </div>
      {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <p>Повідомлення успішно надіслано!</p>
              <button onClick={() => setShowPopup(false)}>Ок</button>
            </div>
          </div>
        )}
      </section>
    );
  };

  export default OrderProduct;