import React from 'react';
import './Footer.css';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import { HashLink } from 'react-router-hash-link';
import Logo from '../../assets/ripor_footer.png'

const Footer = () => {
  return (
    <section>
    <footer id='footer' className="Ripor__Footer">
      <div className='Ripor__Footer-PC'>
        <div className="Ripor__Footer-logo">
          <img src={Logo} alt="Footer_Logo" />
        </div>

        <div className="Ripor__Footer-container">
            <address className="Ripor__Footer-contacts">
              <p>riporkhm@gmail.com</p>
              <p>
                +380673140703
                +380672038080
              </p>
              <p>Хмельницький, вул. Якова Гальчевського</p>
            </address>
          <nav className="Ripor__Footer-NavBar">
          <HashLink to="/"><p>Головна</p></HashLink>
          <HashLink to="/shop#catalog"><p>Магазин</p></HashLink>
          <HashLink to="/#about" spy={true} smooth={true} offset={-70} duration={500}><p>Про нас</p></HashLink>
          <HashLink to="/#feedback" spy={true} smooth={true} offset={-70} duration={500}><p>Відгуки</p></HashLink>
          <HashLink to="/#services" spy={true} smooth={true} offset={-70} duration={500}><p>Послуги</p></HashLink>
          <HashLink to="/#ourworks"><p>Наші Роботи</p></HashLink>
          </nav>
        </div>  

        <div className="Ripor__Footer-bottom">
        <div className="Footer-icons"> 
              <a href='https://www.facebook.com/riporua'><img src={facebook} alt="" /></a>
              <a href='https://www.instagram.com/ripor_ua/'><img src={instagram} alt="" /></a>
            </div>
          <div className="Footer-FAQ">
            <a href='#menu'><p>Privacy Policy</p></a>
            <a href='#menu'><p>Terms an Conditions</p></a>
            <a href='#menu'><p>©2023 Text</p></a>
          </div>
        </div>
      </div>

      <div className="Ripor__Footer-Mobile">
        <div className="Ripor__Footer-logo">
          <img src={Logo} alt="Footer_Logo" />
          </div>

          <div className="Ripor__Footer-container">
            <nav className="Ripor__Footer-NavBar">
            <HashLink to="/"><p>Головна</p></HashLink>
            <HashLink to="/shop"><p>Магазин</p></HashLink>
            <HashLink to="/#about" spy={true} smooth={true} offset={-70} duration={500}><p>Про нас</p></HashLink>
            <HashLink to="/#feedback" spy={true} smooth={true} offset={-70} duration={500}><p>Відгуки</p></HashLink>
            <HashLink to="/#services" spy={true} smooth={true} offset={-70} duration={500}><p>Послуги</p></HashLink>
            <HashLink to="/ourworks#"><p>Наші Роботи</p></HashLink>
            </nav>
            <address className="Ripor__Footer-contacts">
              <p>riporkhm@gmail.com</p>
              <p>
                +380673140703
                +380672038080
              </p>
              <p>Хмельницький, вул. Якова Гальчевського</p>
            </address>
          </div>  

          <div className="Ripor__Footer-bottom">
            <div className="Footer-icons"> 
              <a href='https://www.facebook.com/riporua'><img src={facebook} alt="" /></a>
              <a href='https://www.instagram.com/ripor_ua/'><img src={instagram} alt="" /></a>
            </div>
            <div className="Footer-FAQ">
              <a href='#menu'><p>Privacy Policy</p></a>
              <a href='#menu'><p>Terms an Conditions</p></a>
              <a href='#menu'><p>©2023 Text</p></a>
            </div>
          </div>
      </div>
    </footer>
    </section>
  )
}

export default Footer