import React, {useState, useEffect} from 'react';
import './Ic_Block.css';
import arrow from '../../assets/arrow.svg';
import arrow2 from '../../assets/arrow2.svg';
import Carousel from 'react-elastic-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CatalogItem = ({ imageUrl }) => (
  <div className="imageUrl">
    <img  src={imageUrl} alt="" />
  </div>
);


const IcBlock = () => {
const [error, setError] = useState(null);
const [works, setWorks] = useState([]);
const [currentSlide, setCurrentSlide] = useState(0);
useEffect(() => {
  axios
    .get(`${process.env.REACT_APP_STRAPI_BACK_END}/api/works?populate=*`)
    .then(({ data }) => setWorks(data.data))
    .catch((error) => setError(error));
}, []);

  if(error) return <p>ERROR</p>

  
  return (
    <section id='about'>
    <div className="Ripor__Works">
      <div className="Ripor__Works-content">
        <div className="Ripor__Works-tag">
          <h3>Наші роботи</h3>
        </div>
        <div className="Ripor__Works-text">
          <p>Це гордість і демонстрація нашої бездоганної якості та професіоналізму в галузі ізоляції. Протягом багатьох років ми успішно реалізували низку проектів різної масштабності та складності, що дозволило нам здобути довіру та визнання від наших клієнтів.</p>
        </div>
      </div>
      <div className="Ripor__Works-PC">
          <div className="Ripor__Works-img">
          <Carousel 
          enableSwipe={false}
          showArrows={true}
          itemsToShow={2}
          itemsToScroll={2}
          itemPosition={'center'}
          onChange={(_, newIndex) => setCurrentSlide(newIndex)}
                renderArrow={({ type, onClick }) => (
                  
                  <div className="Ripor__Feedback-header-arrow" onClick={onClick}>
                    {type === 'PREV' ? (
                      <>
                      
                      <img className="arrow" src={arrow2} alt='arrow' />
                      </>
                    ) : (
                      
                      <img className="arrow" src={arrow} alt='arrow2' />
                      
                    )}
                  </div>
                )}
              >
                
            {works.map((item, index) => (
              <Link to={`/works/${item?.id}`}>
              <CatalogItem
                key={index}
                description={item.description}
                autore={item.autore}
                imageUrl={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes?.work_image.data[0].attributes.url}`}
              />
              </Link>
            ))}
            
          </Carousel>
          </div>
        </div>
        <div className="Ripor__Works-Mobile">
        <div className="Ripor__Works-img">
          <Carousel 
          enableSwipe={true}
          showArrows={true}
          itemsToShow={1}
          itemsToScroll={1}
          itemPosition={currentSlide}
          onChange={(_, newIndex) => setCurrentSlide(newIndex)}
                renderArrow={({ type, onClick }) => (
                  
                  <div className="Ripor__Feedback-header-arrow" onClick={onClick}>
                    {type === 'PREV' ? (
                      <>
                      
                      <img className="arrow" src={arrow2} alt='arrow' />
                      </>
                    ) : (
                      
                      <img className="arrow" src={arrow} alt='arrow2' />
                      
                    )}
                  </div>
                )}
              >
                
            {works.map((item, index) => (
              <Link to={`/works/${item?.id}`}>
              <CatalogItem
                key={index}
                description={item.description}
                autore={item.autore}
                imageUrl={`https://admin.ripor.com.ua${item?.attributes?.work_image.data[0].attributes.url}`}
              />
              </Link>
              
            ))}
            
          </Carousel>
          </div>
        </div>
    </div>
    </section>
  )
}

export default IcBlock