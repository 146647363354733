import React, {  useRef, useEffect, useState } from 'react';
import './ShopList.css';
import Carousel from 'react-elastic-carousel';
import arrow from '../../assets/arrow.svg';
import arrow2 from '../../assets/arrow2.svg';
import axios from 'axios';
import { HashLink } from 'react-router-hash-link';

const CatalogItem = ({ description, price_from, imageUrl, price_to }) => (
  <div className="Ripor__ShopList-catalog-item">
    <a href="tovar"><img src={imageUrl} alt='' /></a>
    <a href="tovar"><p>{description}</p></a>
    <a href="tovar"><p> {price_from} - {price_to} ₴ </p></a>

  </div>
);

const ShopList = () => {
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const itemsToShow = 4;
  const carouselRefPC = useRef(null);
  const carouselRefMobile = useRef(null);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_STRAPI_BACK_END}/api/products`, {
        params: {
          populate: '*',
          'pagination[pageSize]': 50
        }
      })
      .then(({ data }) => setProducts(data.data))
      .catch((error) => setError(error));
  }, []);

    if(error) return <p>ERROR</p>
  

  
  const handlePrevPC = () => {
    carouselRefPC.current.slidePrev();
  };

  const handleNextPC = () => {
    carouselRefPC.current.slideNext();
  };

  const handlePrevMobile = () => {
    carouselRefMobile.current.slidePrev();
  };

  const handleNextMobile = () => {
    carouselRefMobile.current.slideNext();
  };

  return (
    <section className="Ripor__ShopList">
      <div className="Ripor__ShopList-PC"> 
      <div className="Ripor__ShopList-header">
        <h3>Також може сподобатись</h3>
        <div className="Ripor__ShopList-header-arrow">
          <button onClick={handlePrevPC}>
            <img src={arrow2} alt="arrow" />
          </button>
          <button onClick={handleNextPC}>
            <img src={arrow} alt="arrow2" />
          </button>
        </div>
      </div>
      <div className="Ripor__ShopList-catalog">
      <Carousel
        itemsToShow={itemsToShow}
        itemsToScroll={itemsToShow}
        renderArrow={({ type, onClick }) => (
          <div
            className="Ripor__ShopList-header-arrow-def"
            onClick={onClick}
          >
            {type === 'PREV' ? (
              <img src={arrow} alt='arrow' />
            ) : (
              <img src={arrow2} alt='arrow2' />
            )}
          </div>
        )}
        ref={carouselRefPC}
      >
        {products.map((item, index) => (
          <HashLink to={`/tovar/${item?.id}#`} key={index}>
            <CatalogItem
              description={item?.attributes.name}
              price_from={item?.attributes.price_from}
              price_to={item?.attributes.price_to}
              imageUrl={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes?.picture.data[0].attributes.url}`}
            />
          </HashLink>
        ))}
      </Carousel>
      </div>
      </div>
      <div className="Ripor__ShopList-Mobile">
        <div className="Ripor__ShopList-header">
          <h3>Також може сподобатися</h3>
          <div className="Ripor__ShopList-header-arrow">
            <button onClick={handlePrevMobile}>
              <img src={arrow2} alt="arrow" />
            </button>
            <button onClick={handleNextMobile}>
              <img src={arrow} alt="arrow2" />
            </button>
          </div>
        </div>
        <div className="Ripor__ShopList-catalog">
          <Carousel
            itemsToShow={2}
            itemsToScroll={2}
            renderArrow={({ type, onClick }) => (
              <div
                className="Ripor__ShopList-header-arrow-def"
                onClick={onClick}
              >
                {type === 'PREV' ? (
                  <img src={arrow} alt='arrow' />
                ) : (
                  <img src={arrow2} alt='arrow2' />
                )}
              </div>
            )}
            ref={carouselRefMobile}
          >
            {products.map((item, index) => (
            <HashLink to={`/tovar/${item?.id}#`}>
              <CatalogItem
                key={index}
                description={item?.attributes.name}
                price_from={item?.attributes.price_from}
                price_to={item?.attributes.price_to}
                imageUrl={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes?.picture.data[0].attributes.url}`}
              />
            </HashLink>
          ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default ShopList;