import React from 'react';

import { Btn, IcBlock, Servicelist } from '../components';
import { Feedback, Footer, Header, History, ExpertsIsolation , Service, Shop} from '../container';

const Home = () => {
  return (
    <>
    <Header />
    <ExpertsIsolation />
    <History />
    <Service/>
    <Servicelist />
    <Shop/>
    <Feedback />
    <IcBlock />
    <Btn />
    
    
    <div className="bgc">
      <Footer />
    </div>
    </>
  );
};

export default Home;