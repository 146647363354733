import React, { useState } from 'react';
import './Basket.css';

const ElementText = ({ name, colors, price_from, price_to, imageUrl, size, materials, size_mms, thickness, complexes }) => {
  const [quantity, setQuantity] = useState(size || 1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };
  const formatThickness = (thickness) => {
    return thickness ? thickness.map(t => `${t}мм`).join(', ') : '';
  };
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <>
      <div className="shopping-catalog-content">
        <div className="shopping_cart-image">
          <a href="#basket"><img src={imageUrl} alt="" /></a>
        </div>
        <div className="Left-upper-side">
          <a className="shopping_name" href="#basket">
            {name || 'Назва відсутня'}
          </a>
          <p className="shopping_color">
            <span>Колір: </span> {colors || 'Відсутній'}
          </p>
          <div className="shopping-card-counter">
            <button onClick={handleDecrement}>-</button>
            <span>{quantity}</span><span>{complexes}</span>
            <button onClick={handleIncrement}>+</button>
          </div>
        </div>

        <div className='Right-upper-side'>
          {materials && materials.length > 0 && (
            <p>
              <span>Матеріал: </span> {materials || 'Відсутній'}
            </p>
          )}
          {size_mms && size_mms.length > 0 && (
            <p>
              <span>Розмір (мм): </span> {size_mms.join(', ')}
            </p>
          )}
          {thickness && (
            <p>
              <span>Товщина (мм): </span> {thickness.join('мм, ')}
            </p>
          )}
          <p className="shopping_price">
            <span>Ціна: </span> {`${price_from} - ${price_to}`}₴
          </p>
        </div>
      </div>

      <div className='shopping-catalog-content-Mobile'>
        <div className="shopping_cart-image">
          <a href="#basket"><img src={imageUrl} alt="" /></a>
        </div>

        <div className="Left-upper-side">
        <a className="shopping_name" href="#basket">
            {name || 'Назва відсутня'}<br />
            {materials} {thickness && thickness.length > 0 && <>{formatThickness(thickness)}&nbsp;</>} 
            {size_mms && size_mms.length > 0 && (
              <>
                {size_mms.map((size, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && ', '}
                    {size}
                  </React.Fragment>
                ))}
              </>
            )}
          </a>
          <p className="shopping_color">
            <span>Колір: </span> {colors || 'Відсутній'}
          </p>
          <div className='Bottom-container'>
            <p className="shopping_price">
              <span>Ціна: </span> {price_from ? `${price_from} - ${price_to}₴` : 'Відсутня'}
            </p>
            <div className="shopping-card-counter">
              <button onClick={handleDecrement}>-</button>
              <div style={{display:'flex', gap:5}}><span>{quantity}</span><span>{complexes}</span></div>
              <button onClick={handleIncrement}>+</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ElementText;
