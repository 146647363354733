import React from 'react';
import './ExpertsIsolation.css';
import pic1 from '../../assets/pic1.png'

const ExpertsIsolation = () => {
  return (
    <section>
    <div className="Ripor__Exp section__pading" id="home">
      <div className="Ripor__Exp-text">
        <h3>Експертна Ізоляція</h3>
      </div>
      <div className="Ripor__Exp-subtext">
        <p>Надійний захист і затишок для вашого житла чи бізнесу з нашими передовими технологіями.</p>
      </div>
      <div className="Ripor__Exp-img">
        <img src={pic1} alt="pic1"/>
      </div>
    </div>
    </section>
  );
};

export default ExpertsIsolation