import React, {useState} from 'react';
import './Service_list.css';

const Servicelist = () => {
  const serviceList = [
  {text :"Складно зв'язатися з менеджером по роботі з клієнтами або представником"},
  {text :"Складно зв'язатися з менеджером по роботі з клієнтами або представником"},
  {text :"Складно зв'язатися з менеджером по роботі з клієнтами або представником"},
  {text :"Складно зв'язатися з менеджером по роботі з клієнтами або представником"},
  {text: "Понад 80% дзвінків клієнтів отримують відповідь за 2 хвилини або менше"},
  {text: "Понад 80% дзвінків клієнтів отримують відповідь за 2 хвилини або менше"},
  {text: "Понад 80% дзвінків клієнтів отримують відповідь за 2 хвилини або менше"},
  {text: "Понад 80% дзвінків клієнтів отримують відповідь за 2 хвилини або менше"}
  ];
  const [activeTab, setActiveTab] = useState('problems');

  return (
    <section>
      <div className="Ripor__serviceList">
        <div className="Ripor__serviceList-PC">
        <div className="Ripor__serviceList-content-problems">
          <h2 className="Ripor__serviceHeader">Ваші проблеми</h2>
          <ul >
          {serviceList.slice(0,4).map((service, index) =>(
            <li key={index}>
              <p>{service.text}</p>
            </li>
          ))}
          </ul>
          </div>

          <div className="Ripor__serviceList-content-solves"> 
          <h2 className="Ripor__serviceHeader">Наші рішення</h2>
            <ul >
            {serviceList.slice(4).map((serv, indext) =>(
              <li key={indext}>
                <p>{serv.text}</p>
              </li>
            ))}
            </ul>
          </div>
          </div>

          <div className="Ripor__serviceList-Mobile">

          <div className="Ripor__serviceHeader">
              <button  onClick={() => setActiveTab('problems')}>
                <h2 >Ваші проблеми</h2>
              </button>
              
              <button onClick={() => setActiveTab('solutions')}>
                <h2 >Наші рішення</h2>
              </button>
          </div>

          <div className="Ripor__serviceList-content-problems" style={{ display: activeTab === 'problems' ? 'block' : 'none' }}>
            <ul>
              {serviceList.slice(0, 4).map((service, index) => (
                <li key={index}>
                  <p>{service.text}</p>
                </li>
              ))}
            </ul>
          </div>

          
          <div className="Ripor__serviceList-content-solves" style={{ display: activeTab === 'solutions' ? 'block' : 'none' }}>
            <ul>
              {serviceList.slice(4).map((serv, indext) => (
                <li key={indext}>
                  <p>{serv.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Servicelist