import React, {useState, useEffect} from 'react'
import './Header.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link, useLocation} from 'react-router-dom';
import ShoppingCart from './Basket';
import Ripor from '../../assets/ripor.svg'
import { HashLink } from 'react-router-hash-link';


const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const location = useLocation();
  const [initialScroll, setInitialScroll] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);
  const toggleShoppingCart = () => {
    setShowShoppingCart(!showShoppingCart);
  };

  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      setCartItemsCount(parsedCartItems.length);
    }

    // Check if the URL contains a hash (e.g., #service) and it's the home page
    if (location.pathname === '/' && window.location.hash) {
      if (doubleClick) {
        setInitialScroll(true);
        setDoubleClick(false);
      }
    }
  }, [showShoppingCart, location.pathname, doubleClick]);

  useEffect(() => {
    if (initialScroll) {
      // Scroll to the target element with a slight delay
      setTimeout(() => {
        const targetElement = document.getElementById(window.location.hash.substring(1));
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setInitialScroll(false); // Reset the state after scrolling
        }
      }, 50);
    }
  }, [initialScroll]);

  return (
    <header  className={`Ripor__Header ${toggleMenu || showShoppingCart ? 'blurred' : ''}`}>
        <div className="Ripor__Header-links">
          <div className='Ripor__Header-links_logo'>
            <Link to="/"><img src={Ripor} alt="" /></Link>
            
          </div>
            <nav className='Ripor__Header-links_container'>
            <HashLink to="/"><p>Головна</p></HashLink>
            <HashLink to="/#about" spy={true} smooth={true} offset={-70} duration={500}><p>Про нас</p></HashLink>
            <HashLink to="/#services" spy={true} smooth={true} offset={-70} duration={500}><p>Послуги</p></HashLink>
            <HashLink to="/shop#catalog"><p>Магазин</p></HashLink>
            <HashLink to="/#feedback" spy={true} smooth={true} offset={-70} duration={500}><p>Відгуки</p></HashLink>
            <HashLink to="/ourworks"><p>Наші Роботи</p></HashLink>
            <HashLink to="/#footer" spy={true} smooth={true} offset={-70} duration={500}><p>Контакти</p></HashLink>
            </nav>
            <div className='Ripor__Header-right-side'>
            <div className="Ripor__Header-order">
              <HashLink to="/service#" spy={true} smooth={true} offset={-70} duration={500}><p>Замовити послугу</p></HashLink>
            </div>
            <button
          type="button"
          className="Ripor__Header-shop-img"
          onClick={() => toggleShoppingCart()}

        >
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M22.713 4.577C22.4317 4.23944 22.0796 3.96795 21.6815 3.78182C21.2835 3.59568 20.8494 3.49946 20.41 3.5H4.242L4.2 3.149C4.11405 2.41942 3.76338 1.74673 3.21449 1.25848C2.6656 0.770223 1.95663 0.500342 1.222 0.5L1 0.5C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5C0 1.76522 0.105357 2.01957 0.292893 2.20711C0.48043 2.39464 0.734784 2.5 1 2.5H1.222C1.46693 2.50003 1.70334 2.58996 1.88637 2.75272C2.06941 2.91547 2.18634 3.13975 2.215 3.383L3.591 15.083C3.73385 16.2998 4.31848 17.4218 5.23391 18.236C6.14934 19.0502 7.33185 19.5 8.557 19.5H19C19.2652 19.5 19.5196 19.3946 19.7071 19.2071C19.8946 19.0196 20 18.7652 20 18.5C20 18.2348 19.8946 17.9804 19.7071 17.7929C19.5196 17.6054 19.2652 17.5 19 17.5H8.557C7.93806 17.4983 7.3348 17.3051 6.82994 16.9471C6.32507 16.589 5.94331 16.0835 5.737 15.5H17.657C18.8293 15.5001 19.9643 15.0882 20.8638 14.3364C21.7633 13.5846 22.37 12.5407 22.578 11.387L23.363 7.033C23.4414 6.60101 23.4237 6.15707 23.3114 5.73264C23.1991 5.30821 22.9948 4.91368 22.713 4.577ZM21.4 6.678L20.614 11.032C20.4891 11.725 20.1245 12.352 19.5839 12.8032C19.0433 13.2544 18.3612 13.5011 17.657 13.5H5.419L4.478 5.5H20.41C20.5569 5.49912 20.7022 5.53062 20.8355 5.59226C20.9689 5.65389 21.087 5.74415 21.1815 5.85661C21.276 5.96907 21.3446 6.10097 21.3824 6.24294C21.4201 6.3849 21.4262 6.53344 21.4 6.678Z" fill="#0C0B0B"/>
<path d="M7.00024 24.501C8.10481 24.501 9.00024 23.6055 9.00024 22.501C9.00024 21.3964 8.10481 20.501 7.00024 20.501C5.89567 20.501 5.00024 21.3964 5.00024 22.501C5.00024 23.6055 5.89567 24.501 7.00024 24.501Z" fill="#0C0B0B"/>
<path d="M17 24.501C18.1046 24.501 19 23.6055 19 22.501C19 21.3964 18.1046 20.501 17 20.501C15.8954 20.501 15 21.3964 15 22.501C15 23.6055 15.8954 24.501 17 24.501Z" fill="#0C0B0B"/>
</g>
<defs>
<clipPath id="clip0_398_15">
<rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
          </svg>
          
          <span className="cart-item-count">{ cartItemsCount }</span>
            </button>
            </div>
        </div>
        
        
        <div className="Ripor__Header-menu">
          {toggleMenu
            ? <RiCloseLine color='#black' size={27} onClick={() => setToggleMenu(false)}/>
            : <RiMenu3Line color='#black' size={27} onClick={() => setToggleMenu(true)}/>
          }
          {toggleMenu && (
            <div className="Ripor__Header-menu_container scale-up-center">
              <nav className="Ripor__Header-menu_container-links">
              <HashLink onClick={() => setToggleMenu(false)} to="/"><p>Головна</p></HashLink>
              <HashLink onClick={() => setToggleMenu(false)} to="/#about" spy={true} smooth={true} offset={-70} duration={500}><p>Про нас</p></HashLink>
              <HashLink onClick={() => setToggleMenu(false)}to="/#services" spy={true} smooth={true} offset={-70} duration={500}><p>Послуги</p></HashLink>
              <HashLink onClick={() => setToggleMenu(false)} to="/shop"><p>Магазин</p></HashLink>
              <HashLink onClick={() => setToggleMenu(false)} to="/#feedback" spy={true} smooth={true} offset={-70} duration={500}><p>Відгуки</p></HashLink>
              <HashLink onClick={() => setToggleMenu(false)} to="/#ourworks"><p>Наші Роботи</p></HashLink>
              <HashLink onClick={() => setToggleMenu(false)} to="/#footer" spy={true} smooth={true} offset={-70} duration={500}><p>Контакти</p></HashLink>
              </nav>
            </div>
          )
            
          }
        </div>
        {showShoppingCart && <ShoppingCart  onClose={toggleShoppingCart} />}
    </header>
  );
};

export default Header