import React, {  useRef, useEffect, useState } from 'react';
import './Feedback.css';
import Carousel from 'react-elastic-carousel';
import arrow from '../../assets/arrow.svg';
import arrow2 from '../../assets/arrow2.svg';
import axios from 'axios';


const FeedbackItem = ({ description, autore, autorImg, data }) => {
  const formattedDate = new Date(data).toLocaleDateString();

  return (
    <div className="Ripor__Feedback-item">
      <p id="discript">{description}</p>
      <div className="whole-autor">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img src={autorImg} alt="" />
          <p id="autor"> {autore} </p>
        </div>
        <p>{formattedDate}</p>
      </div>
    </div>
  );
};


const Feedback = () => {
  const carouselRef = useRef(null);
  const [error, setError] = useState(null); 
  const [feedbacks, setFeedbacks] = useState([]);
  const handlePrev = () => {
    carouselRef.current.slidePrev();
  };

  const handleNext = () => {
    carouselRef.current.slideNext();
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_STRAPI_BACK_END}/api/feedbacks?populate=*`)
      .then((response) => {
        const data = response.data && response.data.data;
        if (data) {
          setFeedbacks(data);
        } else {
          setError("Invalid data format");
        }
      })
      .catch((error) => setError(error));
  }, []);
  

 
  if (error) { 
    return <div>An error occurred: {error.message}</div>; 
  } 

  return (
    <section>
    <div id='feedback' className="Ripor__Feedback">
      <div className="Ripor__Feedback-PC">
        <div className="Ripor__Feedback-content">
        <h3 className="Ripor__Feedback-header">Відгуки</h3>
        <Carousel 
                enableSwipe={false}
                pagination={false}
                showArrows={true}
                itemsToShow={1}
                renderArrow={({ type, onClick }) => (
                  
                  <div className="Ripor__Feedback-header-arrow" onClick={onClick}>
                    {type === 'PREV' ? (
                      <>
                      
                      <img className="arrow" src={arrow2} alt='arrow' />
                      </>
                    ) : (
                      
                      <img className="arrow" src={arrow} alt='arrow2' />
                      
                    )}
                  </div>
                )}
              >
                
                {feedbacks.map((item, index) => (
              <FeedbackItem
                key={index}
                description={item?.attributes.feedback_description}
                autore={item?.attributes.feedback_name}
                autorImg={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes.feedback_image.data[0].attributes.url}`}
                data={item?.attributes.createdAt}
              />
            ))}

            
          </Carousel>
          
        </div>
      </div>

      <div className="Ripor__Feedback-Mobile">
        <div className="Ripor__Feedback-header">
          <p> Відгуки </p>
          <div className="Ripor__Feedback-header-arrow">
            <button onClick={handlePrev}>
              <img src={arrow2} alt="arrow" />
            </button>
            <button onClick={handleNext}>
              <img src={arrow} alt="arrow2" />
            </button>
          </div>
        </div>
        <div className="Ripor__Feedback-content">
          <Carousel
            itemsToShow={1}
            itemsToScroll={1}
            pagination={false}
            renderArrow={({ type, onClick }) => (
              <div className="Ripor__Feedback-header-arrow-def" onClick={onClick}>
                {type === 'PREV' ? (
                  <img src={arrow} alt="arrow" />
                ) : (
                  <img src={arrow2} alt="arrow2" />
                )}
              </div>
            )}
            ref={carouselRef}
          >
            {feedbacks.map((item, index) => (
              <FeedbackItem
                key={index}
                description={item?.attributes.feedback_description}
                autore={item?.attributes.feedback_name}
                autorImg={`${process.env.REACT_APP_STRAPI_BACK_END}${item?.attributes.feedback_image.data[0].attributes.url}`}
                data={item?.attributes.createdAt}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
    </section>
  )
}

export default Feedback