import pic3 from '../../assets/pic3.png';
import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';

const Services = () =>{
    const servicesData = [
        {
          title: "Улаштування та ремонт м'яких покрівель ПВХ, ТПО-мембранами, євроруберойдом",
          description: "3 повним циклом виконання робіт (від укладання пароізоляції до улаштування вирівнюючої стяжки по утеплювачу (мінеральна вата, екструдований полістирол, тощо)"
        },
        {
          title: "Ізоляція трубопроводів і резервуарів з негативними температурними режимами",
          description: "Виконується із застосуванням матеріалів з найбільш замкненою комірчастою структурою, таких як спінений пінополіуретан і тому подібні."
        },
        {
          title: "Теплоізоляція холодильних складів",
          description: "Заізольовані зсередини склади й сховища, морозильні та холодильні камери і приміщення, різко знижують витрати енергії на охолодження."
        },
        {
          title: "Ізоляція енергоносіїв з позитивними температурними режимами",
          description: "Таких як паропроводи, трубопроводи теплових мереж, високотемпературні резервуари, виконується із застосуванням мінераловатних утеплювачів відомих виробників («Rockwool», «Isover», <<Рагос», «Ізоват»)"
        },
        {
          title: "Захисні оболонки",
          description: "Сформовані на спеціальному обладнанні металеві кожухи (оболонки, відводи, трійники, конусні оболонки і купола на ємкості), які призначені для захисту від механічних пошкоджень, ультрафіолетового випромінювання і інших атмосферних впливів."
        }


      ];

    return(
      <section id='services'>
      <div  className="Ripor__Services">
        <div className="Ripor__Services-content">
          <div className="Ripor__Services-tag">
            <h3>Послуги</h3>
          </div>
          <div className="Ripor__Services-text">
            <p>Наша команда професіоналів має багаторічний досвід у галузі ізоляції та використовує передові технології, щоб забезпечити високу якість наших послуг та пропонує інноваційні вирішення ваших проблем.</p>
          </div>
        </div>
        <div className="Ripor__Services-container">
          <div className="Ripor__Services-list">
            <ul>
            {servicesData.map((service, index) => (
              <li key={index}>
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </li>
            ))}
            </ul>
          </div>
          <div className="Ripor__Services-button">
          <Link to="/service">
            <button type="button">Замовити послугу</button>
          </Link>
          </div>
          <div className="Ripor__Services-list-img">
            <img src={pic3} alt=''/>
          </div>
        </div>
        
      </div>
      </section>
    );
  };
  
  export default Services;