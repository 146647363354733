import './WorksCatalog.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const InfoItem = ({ imageUrl, name, description}) => (
  <div className="Catalog-content">
    <a href="#work"><img src={imageUrl} alt="" /></a>
    <a href="#work" id="Name">{name}</a>
    <a href="#work" id="description">{description}</a>
  </div>
);

const WorksCatalog = () => {

 


  const [currentPage, setCurrentPage] = useState(1);
  const [works, setWorks] = useState([]);
  const [error, setError] = useState(null);

  const itemsPerPage = 8; // Кількість елементів на сторінці
  const totalItems = works.length; // Загальна кількість елементів
  const totalPages = Math.ceil(totalItems / itemsPerPage); // Загальна кількість сторінок
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedWorks = works.slice(startIndex, endIndex);
  const pages = []; // Масив сторінок для навігації
  
  useEffect(() => {
    axios
      .get("https://admin.ripor.com.ua/api/works?populate=*")
      .then((response) => {
        const data = response.data && response.data.data;
        if (data) {
          setWorks(data);
        } else {
          setError("Invalid data format");
        }
      })
      .catch((error) => setError(error));
  }, []);
  for (let i = Math.max(1, currentPage - 3); i <= Math.min(totalPages, currentPage + 3); i++) { 
    pages.push(i); 
  } 

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  




  
  

if (error) { 
  return <div>An error occurred: {error.message}</div>; 
} 
  return (
    <section>
    <div id='ourworks' className="Ripor__InfoCatalog">
      <div className="Ripor__InfoCatalog-content">
        <div className="Ripor__InfoCatalog-tag">
          <p>Наші роботи</p>
        </div>
        
      </div>
      <div className="Ripor__InfoCatalog-items">
              {displayedWorks.map((item, id) => (
          <div key={id}>
            <Link to={`/works/${id + 1}`}>
              <InfoItem
                id
                imageUrl={`https://admin.ripor.com.ua${item.attributes?.head_image?.data.attributes.url}`}
                name={item.attributes.work_name}

              />
            </Link>
          </div>
        ))}
      </div>
      <div className="Ripor__InfoCatalog-pagination">
      <button onClick={goToPreviousPage}><FontAwesomeIcon icon={faArrowLeft} /></button>
        {pages.map((page) => (
          <button key={page} onClick={() => goToPage(page)} className={page === currentPage ? 'active' : ''}>
            {page}
          </button>
        ))}
        <button onClick={goToNextPage}><FontAwesomeIcon icon={faArrowRight} /></button>
      </div>
    </div>
    </section>
  );
}

export default WorksCatalog;
