
import React, { useState, useEffect, useRef } from 'react';
import './Tovar.css';
import arrow from '../../assets/arrow.svg';
import arrow2 from '../../assets/arrow2.svg';
import Carousel from 'react-elastic-carousel';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ShoppingCartPopup from '../../container/header/Basket';
import axios from 'axios';
import { Circle } from '@uiw/react-color';

const Tovar = () => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [products, setProducts] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const initialIndex = parseInt(id) - 1;
  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(initialIndex);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const timed = useRef(1);
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedThickness, setSelectedThickness] = useState([]);
  
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  const [addedToCart, setAddedToCart] = useState(false);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    axios
      .get("https://admin.ripor.com.ua/api/products", {
        params: {
          populate: '*',
          'pagination[pageSize]': 50 // Збільшено кількість товарів на сторінку
        }
      })
      .then(({ data }) => {
        setProducts(data.data);
        setTotalSlides(data.data.length);
      })
      .catch((error) => {
        setError(error);
      });
  
    setCurrentSlide(initialIndex);
  
    const storedCartItems = localStorage.getItem('cartItems');
    setCartItems(storedCartItems ? JSON.parse(storedCartItems) : []);
  
    return () => {
      setSelectedColor(null);
      setSelectedMaterials([]);
      setSelectedSize([]);
      setSelectedThickness([]);
      setQuantity(1);
    };
  }, [initialIndex]);
    if(error) return <p>{error}</p>
 
 
    const selectedProduct = products.find(product => product.id === parseInt(id));

  const addToCart = () => {
    const isProductInCart = cartItems.some(item => item.id === selectedProduct?.id);
  
    if (!isProductInCart) {
      const cartItem = {
        id: selectedProduct?.id,
        name: selectedProduct?.attributes.name,
        price_from: selectedProduct?.attributes.price_from,
        price_to: selectedProduct?.attributes.price_to,
        imageUrl: `https://admin.ripor.com.ua${selectedProduct?.attributes.picture?.data[0]?.attributes?.url}`,
        color: selectedColor,
        materials: selectedMaterials,
        size: quantity,
        size_mms: selectedSize,
        complexes: selectedProduct?.attributes?.complexes?.data[0]?.attributes?.c_name,
        thickness: selectedThickness
      };
  
      setCartItems((prevCartItems) => {
        const updatedCartItems = [...prevCartItems, cartItem];
        return updatedCartItems;
      });
  
      localStorage.setItem('cartItems', JSON.stringify([...cartItems, cartItem]));
  
      setAddedToCart(true);
      setTimeout(() => setAddedToCart(false), 3000);
    } else {
      setAddedToCart(false); 
      console.log('Product is already in the cart');
    }
  };
  

  const toggleShoppingCart = () => {
    setShowShoppingCart(!showShoppingCart);
  };
  
  const handleMaterialToggle = (material) => {
    // Check if the material is already selected
    if (selectedMaterials.includes(material)) {
      // If selected, remove it from the list
      setSelectedMaterials((prevMaterials) => prevMaterials.filter((prevMaterial) => prevMaterial !== material));
    } else {
      // If not selected, add it to the list
      setSelectedMaterials((prevMaterials) => [...prevMaterials, material]);
    }
  };

  const handleThicknessToggle = (thickness) => {
    if (selectedThickness.includes(thickness)) {
      setSelectedThickness([]);
    } else {
      setSelectedThickness([thickness]);
    }
  };

  const handleSizeToggle = (size) => {
    // Check if the size is already selected
    if (selectedSize.includes(size)) {
      // If selected, remove it from the list
      setSelectedSize((prevSizes) => prevSizes.filter((prevSize) => prevSize !== size));
    } else {
      // If not selected, add it to the list
      setSelectedSize((prevSizes) => [...prevSizes, size]);
    }
  };

  return (
    <section>
      <div id='tovar' className="Ripor__Tovar">
      <div className="Ripor__Tovar-carousel-1920px">
        <div className="Ripor__Tovar-carousel">
          <div className="Ripor__Tovar-Back" onClick={() => navigate(-timed.current)}>
            <p>
              <FontAwesomeIcon icon={faArrowLeft} />
              <span style={{ marginLeft: '15px' }}>Назад</span>
            </p>
          </div>
          
          {totalSlides > 1 ? (
              <Carousel
                enableSwipe={false}
                itemsToShow={1}
                onChange={() => {
                  timed.current += 1;
                }}
                renderArrow={({ type, onClick }) => (
                  <div onClick={onClick}>
                    {type === 'PREV' ? (
                      <img className="Ripor__Tovar-header-arrow" id="arrow2" src={arrow2} alt="" />
                    ) : (
                      <img className="Ripor__Tovar-header-arrow" id="arrow1" src={arrow} alt="" />
                    )}
                  </div>
                )}
              >
                {selectedProduct?.attributes?.picture?.data.map((picture, index) => (
                  <div className="imageUrl" key={index}>
                    <img src={`https://admin.ripor.com.ua${picture?.attributes?.url}`} alt={selectedProduct?.attributes.name} />
                  </div>
                ))}
              </Carousel>
            ) : (
              <div className="imageUrls" >
                <img src={`https://admin.ripor.com.ua${selectedProduct?.attributes?.picture?.data[0]?.attributes?.url}`} alt={selectedProduct?.attributes.name} />
              </div>
            )}

          </div>
        </div>
        <div className="Ripor__Tovar-carousel-375px">
          <div className="Ripor__Tovar-carousel">
          <div className="Ripor__Tovar-Back" onClick={() => navigate(-timed.current)}>
            <p>
              <FontAwesomeIcon icon={faArrowLeft} />
              <span style={{ marginLeft: '15px' }}>Назад</span>
            </p>
          </div>
          {totalSlides > 1 ? (
          <Carousel
          enableSwipe={true}
          itemsToShow={1}
          onChange={() => {
            timed.current += 1;
          }}
          renderArrow={({ type, onClick }) => (
            <div onClick={onClick}>
              {type === 'PREV' ? (
                <img className="Ripor__Tovar-header-arrow" id="arrow2" src={arrow2} alt="" />
              ) : (
                <img className="Ripor__Tovar-header-arrow" id="arrow1" src={arrow} alt="" />
              )}
            </div>
          )}
        >
          {selectedProduct?.attributes?.picture?.data.map((picture, index) => (
            <div className="imageUrl" key={index}>
              <img src={`https://admin.ripor.com.ua${picture?.attributes?.url}`} alt={selectedProduct?.attributes.name} />
            </div>
          ))}
        </Carousel>
          ) : (
            <div className="imageUrls" >
              <img src={`https://admin.ripor.com.ua${selectedProduct?.attributes?.picture?.data[0]?.attributes?.url}`} alt={selectedProduct?.attributes.name} />
            </div>
          )}
          </div>
          </div>
        <div className="Ripor__Tovar-info">
        <h2 className="Ripor__Tovar-name">{selectedProduct && selectedProduct?.attributes.name}</h2>

          <div className="Ripor__Tovar-price">
            Ціна<p id="price">{selectedProduct && `${selectedProduct?.attributes.price_from } - ${selectedProduct?.attributes.price_to}`}₴</p>
          </div>
          <div className="Ripor__Tovar-color">Колір
          <Circle
            style={{marginTop:'12px'}}
            id='Tovar-color'
            colors={selectedProduct?.attributes?.colors?.data.map(color => ({ color: color.attributes.color_hex || '' }))}
            onChange={(colors) => {
              const colorData = selectedProduct?.attributes?.colors?.data || [];
              const foundColor = colorData.find(color => color.attributes.color_hex.toLowerCase() === colors.hex.toLowerCase());
              if (foundColor) {
                const colorName = foundColor.attributes.color_name;
                console.log(`Color Name: ${colorName}`);
                setSelectedColor(colorName);
              } else {
                console.log('Color not found');
              }
            }}
          />

          </div>

          {selectedProduct?.attributes?.materials?.data.length > 0 && (
            <div className='Ripor__Tovar-material'>
            <p>Матеріал</p>
            <div className='Material-button'>
              {selectedProduct?.attributes?.materials?.data.map((material) => (
                <button
                  key={material.id}
                  onClick={() => handleMaterialToggle(material.attributes.material_name)}
                  className={selectedMaterials.includes(material.attributes.material_name) ? 'selected' : ''}
                >
                  {material.attributes.material_name}
                </button>
              ))}
            </div>
          </div>
          )}
          
          {selectedProduct?.attributes?.size_mms?.data.length > 0 && (
            <div className='Ripor__Tovar-size-mm'>
              <p>Розмір</p>
              <div className='Size-button'>
                {selectedProduct?.attributes?.size_mms?.data.map((size) => (
                  <button
                    key={size.id}
                    onClick={() => handleSizeToggle(size.attributes.size)}
                    className={selectedSize.includes(size.attributes.size) ? 'selected' : ''}
                  >
                    {size.attributes.size}
                  </button>
                ))}
              </div>
            </div>
          )}

          {selectedProduct?.attributes?.thickness && (
            <div className='Ripor__Tovar-thickness'>
              <p>Товщина </p>
              <div className='Thickness-button'>
                {selectedProduct?.attributes?.thickness?.split('\n').map((thicknessValue, index) => (
                  <button
                    key={index}
                    onClick={() => handleThicknessToggle(thicknessValue)}
                    className={selectedThickness.includes(thicknessValue) ? 'selected' : ''}
                  >
                    <div className='Ripor__diameter'>
                    
                      <p style={{ marginRight: window.innerWidth >= 1920 ? '8px' : window.innerWidth >= 450 ? '8px' : '5px' }}></p>
                      {thicknessValue}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )}


          <div className="Ripor__Tovar-size">К-Сть
            <div className="Tovar-shopping-card-counter">
                <button onClick={handleDecrement}>-</button>
                <span>{quantity} {selectedProduct?.attributes?.complexes?.data[0]?.attributes?.c_name}</span>
                <button onClick={handleIncrement}>+</button>
            </div>
          </div>
          
          <div className="Ripor__Tovar-AddtoBasket">
            <button onClick={() => addToCart(selectedProduct)}>
              <FontAwesomeIcon icon={faShoppingCart} />
              <span style={{ marginLeft: '15px' }}>
                {addedToCart ? 'Товар додано до кошика!' : 'Замовити'}
              </span>
            </button>
          </div>

          {showShoppingCart && <ShoppingCartPopup cartItems={cartItems} setCartItems={setCartItems} onClose={toggleShoppingCart} />}
        </div>
      </div>
      <div className="Ripor__Tovar-Text">
        <h3 className="Info">Більше Інформації</h3>
        <div className="description">
          <div className='description_info'>
            <h2 className='description_head'>Загальний опис</h2>
            <p className='descr_text'>{selectedProduct && selectedProduct?.attributes.description}</p>
          </div>
          {selectedProduct?.attributes?.aspects?.length > 0 && (
          <div className='Tovar_aspects'>
            <h2 className='advantages_head'>Аспекти</h2>
            <ul className="Ripor__Tovar-aspect-list">
              
              {selectedProduct?.attributes?.aspects
                ? selectedProduct?.attributes?.aspects?.split('\n').map((aspect, index) => (
                    <li key={index}>{aspect}</li>
                  ))
                : null}
            </ul>
          </div>
          )}
        </div>
        <div className="description_Mobile">
          {selectedProduct?.attributes?.aspects?.length > 0 && (
          <div className='Tovar_aspects'>
            <h2 className='advantages_head'>Аспекти</h2>
            <ul className="Ripor__Tovar-aspect-list">
              
              {selectedProduct?.attributes?.aspects
                ? selectedProduct?.attributes?.aspects?.split('\n').map((aspect, index) => (
                    <li key={index}>{aspect}</li>
                  ))
                : null}
            </ul>
          </div>
          )}
          <div className='description_info'>
            <h2 className='description_head'>Загальний опис</h2>
            <p className='descr_text'>{selectedProduct && selectedProduct?.attributes.description}</p>
          </div>
        </div>
      </div>
    </section>
  );
};


export default Tovar;