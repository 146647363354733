import React, { useRef, useState } from 'react';
import './OrderServ.css';
import './TextBox'
import { useNavigate } from 'react-router-dom';
import contact from '../../assets/contact.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const OrderServ = () => {
  const navigate = useNavigate();

  const form = useRef();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {

      const userEmail = form.current.querySelector('input[name="user_email"]').value;
      const userName = form.current.querySelector('input[name="user_name"]').value;
      const userPhone = form.current.querySelector('input[name="user_phone"]').value;
      const userMessage = form.current.querySelector('input[name="user_message"]').value;


      const emailContent = `
        <p><strong>Ім'я замовника:</strong> ${userName}</p>
        <p><strong>Email замовника:</strong> ${userEmail}</p>
        <p><strong>Номер телефону:</strong> ${userPhone}</p>
        <p><strong>Повідомлення:</strong> ${userMessage}</p>
      `;


      await axios.post('https://admin.ripor.com.ua/api/email', {
        to: 'riporkhm@gmail.com',
        subject: 'Замовлення',
        html: emailContent,
      });
      setShowPopup(true);
      console.log('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };

  const formatPhone = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    let formattedValue = cleanedValue.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    return formattedValue;
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;

    setPhone(formatPhone(inputValue));
  };

  return (
    <section  className="Ripor__OrderService">
      <div id='service' className="Ripor__OrderService-container">
        <div className="Ripor__OrderService-Back" onClick={() => navigate(-1)}>
          <p><FontAwesomeIcon icon={faArrowLeft} /><span style={{ marginLeft: '15px' }}>Назад</span></p>
        </div>
        <div className="Ripor__OrderService-Info">
          <h2>Замовити послугу</h2>
          <p>Заповніть форму, і ми зв'яжемося з вами найближчим часом.</p>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div className='TextBoxRow'>
            <input
              name='user_name'
              className="TextBox1"
              type="text"
              placeholder="Ім'я"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <input
              name='user_phone'
              className="TextBox1"
              type="text"
              placeholder="Номер телефону"
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
          <div className="TextBoxRow2">
            <input
              name='user_email'
              className="TextBox2"
              type="text"
              placeholder="Email"
            />
            <input
              name='user_message'
              className="TextBox2"
              type="text"
              placeholder="Повідомлення"
            />
          </div>
          <div className="Ripor__OrderService-button">
            <input type="submit" value="Надіслати" />
          </div>
        </form>
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <p>Повідомлення успішно надіслано!</p>
              <button onClick={() => setShowPopup(false)}>Ок</button>
            </div>
          </div>
        )}
      </div>
      <div className="Ripor__OrderService-imgLogo">
        <img src={contact} alt="" />
      </div>
    </section>
  );
}

export default OrderServ;
