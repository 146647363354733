import React from 'react';

import { Portfolio} from '../components';
import { Footer, Header} from '../container';

const Catalogitem = () => {
  return (
    <>
    <Header/>
    <Portfolio />
    <div className="bgc">
      <Footer />
    </div>
    </>
  );
};

export default Catalogitem;