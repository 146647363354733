import './InfoClg.css'; 
import { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom'; 
import axios from 'axios'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const InfoItem = ({ imageUrl, name, price_from, price_to }) => (
  <div className="Catalog-content-tovars">
    <img src={imageUrl} alt={name} />
    <div id="Name">
      {name}
    </div>
    <div id="description">
     {price_from} - {price_to}₴ 
    </div>
  </div>
);

const InfoClg = () => {
  const [error, setError] = useState(null); 
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [sortOption, setSortOption] = useState(''); // Початкове значення порожньої рядки 
  const [sortedProducts, setSortedProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);  
  const [isActive, setIsActive] = useState(false); 

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_STRAPI_BACK_END}/api/products`, {
        params: {
          populate: '*',
          'filters[is_exist][$eq]': true,
          'pagination[pageSize]': 50 // Збільшено кількість товарів на сторінку
        }
      })
      .then(({ data }) => {
        console.log("Loaded products:", data.data); // Додано логування
        const productIds = data.data.map(product => product.id);
        console.log("Product IDs:", productIds); // Логування ID товарів
        setProducts(data.data);
        setSortedProducts(data.data); // Ініціалізуємо відсортовані продукти
      })
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    let sortedProductsCopy = [...products];
  
    if (sortOption === 'name') {
      sortedProductsCopy.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
    } else if (sortOption === 'ціна') {
      sortedProductsCopy.sort((a, b) => b.attributes.price_from - a.attributes.price_from);
    }
  
    setSortedProducts(sortedProductsCopy);
  }, [sortOption, products]);

  if (error) { 
    return <div>An error occurred: {error.message}</div>; 
  } 

  const handleSort = (eventKey) => {
    setSortOption(eventKey);
    setIsOpen(false);
    setIsActive(false);
  };

  const toggleMenu = () => { 
    setIsOpen(!isOpen); 
    setIsActive(!isActive); 
  }; 

  const itemsPerPage = 8; 
  const totalItems = sortedProducts.length; 
  const totalPages = Math.ceil(totalItems / itemsPerPage); 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedProducts = sortedProducts.slice(startIndex, endIndex);
  const pages = []; 

  for (let i = Math.max(1, currentPage - 3); i <= Math.min(totalPages, currentPage + 3); i++) { 
    pages.push(i); 
  } 

  const goToPage = (page) => { 
    if (page >= 1 && page <= totalPages) { 
      setCurrentPage(page); 
    } 
  }; 

  const goToPreviousPage = () => { 
    if (currentPage > 1) { 
      setCurrentPage(currentPage - 1); 
    } 
  }; 

  const goToNextPage = () => { 
    if (currentPage < totalPages) { 
      setCurrentPage(currentPage + 1); 
    } 
  }; 

  return (
    <section id='catalog'>
      <div className="Ripor__Catalog">
        <div className="Ripor__Catalog-content">
          <div className="Ripor__Catalog-tag">
            <h2>Магазин</h2>
          </div>
          <div className="Ripor__Catalog-sorting">
            <div className={`dropdown ${isOpen ? 'open' : ''}`}>
              <button
                className={`dropdown-toggle ${isOpen ? 'active' : ''}`}
                type="button"
                id="sorting-dropdown"
                onClick={toggleMenu}
              >
                {sortOption ? `Сортування: ${sortOption}` : 'Сортування'}{' '}
                <i
                  className={`fa fa-chevron-down ${isActive ? 'active' : ''}`}
                ></i>
              </button>
              {isOpen && (
                <div className="dropdown-menu" aria-labelledby="sorting-dropdown">
                  <button className="dropdown-item" onClick={() => handleSort('назва')}>
                    По назві
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => handleSort('ціна')}
                  >
                    По ціні
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="Ripor__Catalog-items">
          {displayedProducts.map(({ id, attributes }) => (
            <div key={id}>
              <Link to={`/tovar/${id}`}>
                <InfoItem
                  id
                  imageUrl={`${process.env.REACT_APP_STRAPI_BACK_END}${attributes.picture.data[0].attributes.url}`}
                  name={attributes.name}
                  price_from={attributes.price_from}
                  price_to={attributes.price_to}
                />
              </Link>
            </div>
          ))}
        </div>

        <div className="Ripor__Catalog-pagination">
          <button onClick={goToPreviousPage}><FontAwesomeIcon icon={faArrowLeft} /></button>
          {pages.map((page) => (
            <button key={page} onClick={() => goToPage(page)} className={page === currentPage ? 'active' : ''}>
              {page}
            </button>
          ))}
          <button onClick={goToNextPage}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
      </div>
    </section>
  );
}

export default InfoClg;