import React from 'react';
import obolon from '../../assets/obolon.png';
import nemirof from '../../assets/nemirof.png';
import koha from '../../assets/koha.png';
import yagotinske from '../../assets/yagotinske.png';
import './Btn.css';
import { Link } from 'react-router-dom';

const Btn = () => {
  return (
    <section>
    <div className="Ripor__icons">
      <div className="Ripor__icons-wrapper">
        <img src={obolon} alt="" />
        <img src={nemirof} alt="" />
        <img src={koha} alt="" />
        <img src={yagotinske} alt="" />
      </div>
      <div className="Ripor__Contact">
        <div className="Ripor__Contact-header">
          <h1>Не можеш знайти свою відповідь?</h1>
        </div>
        <Link to="/service"><button className="Ripor__Contact-button" type="button">Зв’язатися з нами</button></Link>
      </div>
    </div>
    </section>
  )
}

export default Btn