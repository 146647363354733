import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Basket.css';
import ElementText from './ElementText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const ShoppingCartPopup = ({ onClose }) => {
  const getInitialCartHeight = () => {
    return window.innerWidth <= 380 ? window.innerHeight : 800;
  };

  const [cartItems, setCartItems] = useState([]);
  const [cartHeight, setCartHeight] = useState(getInitialCartHeight());


  const prevCartItems = useRef();

  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== productId);
    setCartItems(updatedCartItems);
  };


  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      setCartItems(parsedCartItems);

      const newHeight = getInitialCartHeight() + parsedCartItems.length * 200;
      setCartHeight(newHeight);

      const body = document.querySelector('body');
      if (body && window.innerWidth <= 800) {
        body.style.overflow = 'hidden';
      }
    }

    return () => {
      const body = document.querySelector('body');
      if (body && window.innerWidth <= 800) {
        body.style.overflow = 'auto';
      }
    };
  }, []);

  // Використовуємо useCallback, щоб функція не змінювалася при кожному рендері
  const updateLocalStorage = useCallback(() => {
    // Оновлюємо локальне сховище лише якщо є зміни в cartItems
    if (prevCartItems.current !== cartItems) {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      prevCartItems.current = cartItems;
    }
  }, [cartItems]);

  useEffect(() => {
    updateLocalStorage();
  }, [updateLocalStorage]);

  const isCartFull = cartItems.length > 0;

  return (
    <section className="Ripor__shopping-cart-popup" style={{ height: cartHeight }}>
      <div className="Ripor__shopping-cart_Header">
        <h2>Кошик</h2>
        <button className="close-button" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
      <div className="Ripor__shopping-cart_items">
        {cartItems.map((product, cartid) => (
          <div className="shopping_cart-container" key={cartid}>
            <ElementText
              name={product.name}
              colors={product.color}
              price_from={product.price_from}
              price_to={product.price_to}
              size_mms={product.size_mms}
              materials={product.materials}
              thickness={product.thickness}
              imageUrl={product.imageUrl}
              size={product.size}
              complexes={product.complexes}
            />
            <div className="color-name">{product.colorName}</div>
            <div className="Delete-button">
              <button type="button" onClick={() => removeFromCart(product.id)}>
              <FontAwesomeIcon icon={faTrashCan}  />
              </button>
            </div>
          </div>
        ))}
      </div>
      {isCartFull && (
        <div className="shopping-order-container">
          <button className='shopping-order-button' type='button'>
            <Link
              to={{
                pathname: "/order/product",
                state: { cartItems }
              }}
            >
              <p>Замовити</p>
            </Link>
          </button>
        </div>
      )}
    </section>
  );
};

export default ShoppingCartPopup;
