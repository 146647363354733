import React from 'react';
import './History.css';
import pic2 from '../../assets/pic2.png';


const history = () => {
  return (
    <section className="Ripor__History">
      <div className="Ripor__History-content">
        <div className="Ripor__History-tag">
          <h3>Наша Історія</h3>
        </div>
        <div className="Ripor__History-text">
          <p>2014 рік. Лише 3 співробітники, декілька радників та багато амбіцій. Наша мета - створити привітний, доступний та захоплюючий бізнес для персоналу та клієнтів.</p>
        </div>
      </div>
        <div className="Ripor__History-img">
          <img src={pic2} alt="pic2"/>
        </div>
    </section>
    );
};

export default history;


