import React, { useState, useEffect } from 'react';
import './Portfolio.css';
import arrow from '../../assets/arrow.svg';
import arrow2 from '../../assets/arroww.svg';
import Carousel from 'react-elastic-carousel';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const Portfolio = () => {
  const { product_id } = useParams();

  const [works, setWorks] = useState([]);
  const [error, setError] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false); // Add a state to track image loading

  const initialIndex = parseInt(product_id) - 1;
  const [currentSlide, setCurrentSlide] = useState(initialIndex);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_STRAPI_BACK_END}/api/works?populate=*`)
      .then((response) => {
        const data = response.data && response.data.data;
        if (data) {
          setWorks(data);
          setImagesLoaded(true); // Set imagesLoaded to true when works are loaded
        } else {
          setError("Invalid data format");
        }
      })
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }


  const currentWork = works[currentSlide];

  // Check if images are loaded before rendering the carousel
  if (!imagesLoaded || !currentWork) {
    return <div>Loading...</div>;
  }
  if (!imagesLoaded || !currentWork) {
    return <div>Loading...</div>;
  }
  const workImages = currentWork.attributes.work_image.data;

  return (
    <section className="Ripor__Portfolio">
      <div className='Ripor__Portfolio-Header'>
        <h1>
          {currentWork?.attributes?.work_name}
        </h1>
      </div>
      <div className="Ripor__Portfolio-carousel-1920px">
        <div className="Ripor__Portfolio-carousel">
          <Carousel
            isInfinite={true}
            enableSwipe={false}
            showArrows={true}
            itemsToShow={1}
            itemsToScroll={1}
            renderArrow={({ type, onClick }) => (
              <div className="Ripor__Portfolio-header-arrow" onClick={onClick}>
                {type === 'PREV' ? (
                  <img className="Portfolio_arrow" src={arrow2} alt="arrow" />
                ) : (
                  <img id="arr2" className="Portfolio_arrow" src={arrow} alt="arrow2" />
                )}
              </div>
            )}
          >
            {workImages.map((image, index) => (
              <div key={index} className="Portfolio_imageUrl">
                <img src={`${process.env.REACT_APP_STRAPI_BACK_END}${image.attributes.url}`} alt="" />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="Ripor__Portfolio-carousel-375px">
        <div className="Ripor__Portfolio-carousel">
            <Carousel
              enableSwipe={true}
              showArrows={true}
              itemsToShow={1}
              itemsToScroll={1}
              renderArrow={({ type, onClick }) => (
                <div className="Ripor__Portfolio-header-arrow" onClick={onClick}>
                  {type === 'PREV' ? (
                    <img className="Portfolio_arrow" src={arrow2} alt="arrow" />
                  ) : (
                    <img id="arr2" className="Portfolio_arrow" src={arrow} alt="arrow2" />
                  )}
                </div>
              )}
            >
              {workImages.map((image, index) => (
              <div key={index} className="Portfolio_imageUrl">
                <img src={`${process.env.REACT_APP_STRAPI_BACK_END}${image.attributes.url}`} alt="" />
              </div>
            ))}
            </Carousel>
          </div>   
      </div>
      <div>
      <div className="Ripor__Portfolio-content">
        <div className="Ripor__Portfolio-tag">
          <h2>Основні аспекти</h2>
        </div>
        <ul className="Ripor__Portfolio-list">
          {currentWork?.attributes?.work_aspects
            ? currentWork?.attributes?.work_aspects?.split('\n').map((aspect, index) => (
                <li key={index}>{aspect}</li>
              ))
            : null}
        </ul>
      </div>
      <div className="Ripor__Portfolio-content">
        <div className="Ripor__Portfolio-tag">
          <h2>Про роботу</h2>
        </div>
        <div className="Ripor__Portfolio-text">
          <p>
            {currentWork?.attributes?.work_description?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
      </div>
      
      </div>
    </section>
  );
};

export default Portfolio;

